
import {Component, Vue} from "vue-property-decorator";
import Settings from "@/services/Settings";
import {v4 as uuidv4} from "uuid";

@Component({
  filters: {

  },
  components: {
  }
})
export default class Vote extends Vue {
  private host: string;
  private storageHost: string;
  private uid: string;
  private loader: boolean;
  private stats: any;
  private topValue: number;

  private showModal: boolean;
  private selectedItem: any;
  private showVoting: boolean;
  private readonly voteKey: string = 'tvn-nissanxtopmodel-vote';
  private readonly voteKeyValue: string = 'tvn-nissanxtopmodel-vote-value';
  private readonly voteKeyItem: string = 'tvn-nissanxtopmodel-vote-item';
  private readonly voteKeyTime: string = 'tvn-nissanxtopmodel-vote-time';
  private voteValue: string;
  private voteItem: string;
  private voteTime: number;
  private marginTop = 0;
  private screenHeight = 0;
  private projectSettings = {};
  private settingsLoaded = false;
  private projectViewSettings = {};
  private showStats = false;

  constructor() {
    super();
    const settings = new Settings();
    this.host = settings.getSetting('apiHost');
    this.storageHost = settings.getSetting('storagePath');
    this.loader = false;
    this.stats = [];
    this.uid = '6a3a82356ba5385e3f8521789290f398';
    this.topValue = 0;
    this.showModal = false;
    this.selectedItem = {};
    this.showVoting = false;
    this.voteTime = 0;
    if (localStorage.getItem(this.voteKeyTime) !== null) {
      this.voteTime = parseInt(localStorage.getItem(this.voteKeyTime) as string);
    }
    if (this.voteTime < ((new Date().getTime() / 1000) - 24 * 60) && localStorage.getItem(this.voteKey) !== null) {
      localStorage.removeItem(this.voteKey);
      localStorage.removeItem(this.voteKeyItem);
      localStorage.removeItem(this.voteKeyValue);
    }
    if (localStorage.getItem(this.voteKey) !== null) {
      this.showVoting = parseInt(localStorage.getItem(this.voteKey) as string) === 1;
    }
    this.voteItem = '';
    if (localStorage.getItem(this.voteKeyItem) !== null) {
      this.voteItem = localStorage.getItem(this.voteKeyItem) as string;
    }
    if (localStorage.getItem(this.voteKeyValue) !== null) {
      this.voteValue = localStorage.getItem(this.voteKeyValue) as string;
    } else {
      this.voteValue = uuidv4();
      localStorage.setItem(this.voteKeyValue, this.voteValue);
    }
    // console.log(this.voteValue);
    const urlParams = new URLSearchParams(window.location.search);
    this.showStats = urlParams.has('show_stats');
  }

  created() {
    this.getData();
    this.getProjectSettings();
  }

  voteDescription(value: number) {
    if (value===1) {
      return "głos";
    }
    else if (value===2 || value===3 || value===4) {
      return "głosy";
    }
    else if (value > 20 && (value%10===2 || value%10===3 || value%10===4)) {
      return "głosy"
    }
    else {
      return "głosów";
    }
  }

  getProjectSettings() {
    this.$http.get(this.host + '/apis/' + this.uid + '/project', {

    }).then((response: any) => {
      this.projectSettings = response.data;
      this.projectViewSettings = response.data.credentials.no_feed;
      this.settingsLoaded = true;
    });
  }

  onAddVote(item: any) {
    this.saveVote(
        this.voteValue,
        item
    );
  }

  saveVote(key: string, item: any) {
    this.$http.post(
        this.host + '/vote/site', {
          author_id: key,
          project_uid: this.uid,
          params: item
        }
    ).then((response: any) => {
      console.log(response);
      this.showModal = false;
      this.$notify({
        group: 'notify',
        type: 'success',
        title: 'Głos oddany',
        text: 'Dziękujemy za oddanie głosu!'
      });
      localStorage.setItem(this.voteKey, '0');
      localStorage.setItem(this.voteKeyItem, item.group.name);
      localStorage.setItem(this.voteKeyTime, (new Date().getTime() / 1000).toString())
      this.voteItem = item.group.name;
      this.showVoting = false;
    }, (error: any) => {
      console.log(error);
      this.showModal = false;
      this.$notify({
        group: 'notify',
        type: 'error',
        title: 'Nie udało się zagłosować',
        text: 'Wystąpił błąd w trakcie głosowania'
      });
    })
  }

  getData() {
    if (this.loader) return;
    this.loader = true;
    this.$http.get(this.host + '/api/nofeed/' + this.uid, {
      params: {}
    }).then((response: any) => {
      this.stats = response.data.stat;
      const values = response.data.stat
          .map((v: any) => v.value)
          .sort((a: number, b: number) => b - a)
          .splice(0, 3)
          .reverse()
      ;
      if (values[0] !== undefined) {
        this.topValue = values[0];
      }
    }, response => {
      console.log(response);
      this.loader = false;
    });
  }
}
